@import '../../styles/variables';

.search-result {
    .search-detail {
        cursor: pointer;
        display: block;
        border: 0.5px solid #DBDEE1;
        border-radius: 4px;
        margin: 0;
        padding: 20px 50px;
        margin-bottom: 15px;

        &__title {
            font-size: 24px;
            font-weight: 600;
            margin: 0;
        }

        &__content{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            &__title {
                font-size: 24px;
                font-weight: 600;
                margin: 0;
            }

            &__company-logo {
                width: auto;
                max-height: 64px;
            }

            &__analysts {
                display: flex;
                flex-direction: row;

                img {
                    height: 64px;
                    width: 64px;
                }
            }

            &__ticker-table {
                flex: 0.7;
            }
        }
    }
}

@media screen and (min-width: $mobile-screen + 1px) {
    .search-result {
        .search-detail__title {
            img {
                display: none;
            }
        }

        .search-detail__content__ticker-table {
            flex: initial;
        }
    }

    .mobile-search-results {
        display: none;
    }

    .search-result-page {
        .ticker {
            .column-title {
                display: none;
            }
        }

        .heading {
            display: none;
        }

        .custom-filter-column {
            margin-top: 91px;
        }
    }
}

@media screen and (max-width: $mobile-screen) {
    .mobile-search-results {
        .search-result {
            .search-from__count-result {
                display: block;
            }
        }
    }

    .search-result {
        .search-from__count-result {
            display: none;
        }
    }

    .search-result-page {
        .heading {
            margin-bottom: 0;
        }

        .table__wrapper {
            .table__body {
                .table__cell {
                    &:nth-child(1) {
                        order: 3;

                        .cell-wrapper-body {
                            font-size: 15px;
                        }
                    }
                    &:nth-child(2) {
                        order: 1;

                        .label-tag {
                            font-size: 12px;
                            font-weight: 400;
                            padding: 7px 25px;
                            display: inline-block;
                            font-weight: 500;
                        }
                    }
                    &:nth-child(3) { order: 4; }
                    &:nth-child(4) { order: 2; }
                }
            }
        }
    }

    .mobile-search-results {
        margin-bottom: 12px;
        padding-bottom: 6px;
        padding-left: 12px;
        padding-right: 12px;
        margin-left: -12px;
        margin-right: -12px;
        border-bottom: 1px solid #e8e8e8;

        .search-from__count-result {
            margin: 0 !important;
        }

        .search-result {
            margin: 0;
        }
    }

    .search-adds {
        margin-bottom: 24px;
    }

    form {
        width: 100%;
    }

    .search-result {
        margin-top: 24px;
        margin-bottom: 24px;

        .search-from {
            display: none;
        }

        .search-from__count-result {
            font-size: 17px;
            line-height: 1.3;
            font-weight: 600;
            color: $dark_indigo;
            margin-bottom: 24px;
            margin-top: 6px;
        }

        .search-detail {
            padding: 12px;
            border: none;
            box-shadow: none;
            background-color: rgba(251, 251, 251, 1);
            border: 1px solid rgba(243, 244, 245, 1);
            border-radius: 8px;

            .plate__content {
                margin-right: -12px;

                .search-detail__title {
                    font-size: 17px;
                    line-height: 1.3;
                    font-weight: 600;
                    display: flex;
                    align-items: center;

                    img {
                        margin-right: 12px;
                    }
                }

                .search-detail__content {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    width: 100%;

                    .img-search {
                        display: none;
                    }

                    .search-detail__content__analysts {
                        order: 2;
                    }

                    .search-detail__content__ticker-table {
                        width: 100%;
                        order: 1;

                        table {
                            width: 100%;
                        }

                        .ticker {
                            .ticker__row_type_head {
                                display: none;
                            }

                            .ticker__row_type_body {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-start;

                                .ticker__cell {
                                    width: 100%;
                                    display: flex;
                                    flex-direction: column;
                                    align-items: flex-start;
                                    font-size: 16px;
                                    line-height: 1.3;
                                    font-weight: 400;
                                    padding: 12px 0;
                                    padding-right: 0;
                                    border-bottom: 1px solid rgba(11, 31, 53, 0.1);

                                    .target-price__amount {
                                        color: $dark_indigo;

                                        .price-status--default {
                                            color: $dark_indigo;
                                            font-weight: 400;   
                                        }
                                    }

                                    .column-title {
                                        font-size: 12px;
                                        line-height: 1.3;
                                        font-weight: 400;
                                        color: rgba(109, 121, 134, 1);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@import '../../styles/variables';

.date-filter {

    &__item {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        padding: 15px 8px 0 8px;
        &_label {
            color: $dark_indigo_60;
            font-size: 16px;
            line-height: 24px;
            flex: 0.5;
            padding: 0 13px;
        }
        &_input {
            flex: 2;
        }
        &:last-child {
            padding-bottom: 27px;
        }
    }
}
@import './variables';

// @media screen and (max-width: 1500px) {
//   body {
//     width: 1920px;
//   }
// }

body,
html {
  height: 100%;
  font-family: BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', 'Roboto Rouble', sans-serif;
  font-size: 14px;
  color: $dark_indigo;
}

*, *:after, *:before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}


body, html {
  //margin: 0;
  //padding: 0;
  height: 100%;
}


a {
  text-decoration: none;
}

#root {
  height: 100%;
}

.admin-main {
  content: "";
  display: table;
  clear: both;
}

.admin-main {
  margin: 0 auto;
  width: 100%;
  padding: 0;
  min-height: 100%;
  position: relative;

  &__menu {
    width: 250px;
    float: left;
    background-color: $dark_indigo;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    overflow: auto;

  }

  &__container {
    width: 87%;
    float: right;

    &_header {
      margin-bottom: 20px;
      text-align: right;
      border-bottom: 1px solid $dark_indigo_05;
    }

    &_main {
      padding: 0 20px;
    }

    @media screen and (max-width: 1660px) {
      width: 84%;
    }
  }
}


.admin-left-menu, .admin-container {
  padding: 0;
}

.admin-spacer {
  display: block;
  width: 100%;
  height: 15px;
}

.separate {
  border-bottom: 1px solid rgba(240, 50, 38, .85);
}

.authors-list {
  padding-top: 5px;
  padding-bottom: 5px;
}

.authors-list.color,
.authors-list .label_theme_alfa-on-white,
.authors-list .link_theme_alfa-on-white {
  color: rgba(11, 31, 53, .6);
}

.authors-list:hover,
.authors-list:hover .label_theme_alfa-on-white,
.authors-list:hover .link_theme_alfa-on-white {
  color: rgba(11, 31, 53, 1);
}

.admin-image img {
  width: 70%;
  position: relative;
  top: -40%;
}

.admin-author .textarea__control {
  max-width: 1168px;
  float: right;
}

.admin-table-header {
  white-space: nowrap;
  border: 1px solid #dbdee1;
  margin: 0;
}

.admin-table-header.catalog {
  margin-right: 10%;
}

.admin-table-body-data {
  align-items: center;
  white-space: nowrap;
  margin-right: 10%;
}

.admin-table-body-data:hover {
  background-color: #f3f4f5;
  cursor: pointer;
}

.admin-table-body-data.catalog {
  margin: 10px 10% 10px 0px;
}

.admin_table_data_cell {
  justify-content: end;
  color: #546271;
  white-space: nowrap;
  padding: 10px 30px 10px 10px;
}

.border-link-bottom {
  border-color: rgba(11, 31, 53, .3);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  transition: border-color 200ms ease-out;
}

.break_words_cell {
  white-space: normal;
}

.admin_table_header_cell {
  justify-content: end;
  color: #546271;
  white-space: nowrap;
  border-right: 1px solid #dbdee1;
  padding: 10px 30px 10px 10px;
}

.company-inner {
  & > .announcements {
    display: none;
  }

  .ticker {
    padding-top: 90px;
  }

  .general-form__tabs {
    max-width: 75%;
  }

  .report-mar {
    form {
      width: 100%;
      max-width: 284px;
    }
  }
}

img {
  display: block;
  width: 100%;
}

.filter-sidebar__item {
  padding-top: 90px;
}

.filter-sidebar__item-a {
  padding-top: 45px;
}

.our-sidebar__item {
  padding-top: 0px;
  margin-top: -16px;
}

.pdf-icon-t svg {
  width: 27px;
  height: 27px;
}

.not-allowed {
  cursor: not-allowed;
}

.author-table {
  display: block;
}

.analyst-profile {
  margin-top: 90px;
  display: flex;
  flex-direction: row;

  .analyst-profile__wrapper {
    display: flex;
  }

  &__photo {
    flex: 0.6;
    padding: 0 25px;

    img {
      height: 230px;
      width: 230px;
      border-radius: 50%;
    }
  }

  &__contact {
    flex: 1.5;
    padding-left: 55px;
    padding-right: 25px;

    &__fullname {
      font-weight: bold;
      font-size: 28px;
      line-height: 36px;
      margin: 0;
      padding: 0;
    }

    span {
      display: block;
      font-size: 16px;
      padding: 2px 0;
      opacity: 0.7;
    }

    &__post {
      margin-bottom: 30px;
    }
  }

  &__about {
    flex: 3;
    padding: 10px 0;
  }
}

.link .fullname {
  cursor: pointer;
  outline: none;
  text-decoration: none;
  transition: color 200ms ease-out;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
}

.link:hover .fullname {
  color: #ef3124;
}

.custom-link .link__text {
  text-decoration: none;
  border: none;
  outline: none;
  transition: color 200ms ease-out;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0.1);
}

.custom-link:hover .link__text {
  color: #ef3124;
}

.link {
  &:hover {
    color: $red_brand;
  }

  span:hover {
    span:hover {
      border-color: $red_brand;
    }
  }

  &--off-border {
    span {
      span {
        border: none;
      }
    }
  }
}

.user-navigation a {
  text-decoration: none;
  color: #0b1f35;
}

.standart-table-m {
  margin-top: -18px;
}

.company-text {
  margin-left: 40px;
}

.graf-image img {
  height: 200px;
  width: 100%;
  //background: #000;
}

.head-company-text {
  font-size: 24px;
  margin: 20px 0px;
}

.body-ma {
  margin-top: 10px;
}

.files-link {
  margin: 15px auto;
  width: 33%;
}

.margin-t {
  margin-top: 10px;
}

.stock-title {
  font-size: 16px;
  font-weight: bold;
}

.gray-stock {
  margin-left: 5px;
  font-weight: normal;
  //color: rgb(150, 158, 168);
}

.button-filter {
  margin-right: 30px;
  display: inline-block;
  float: right;
}

.button-stock {
  margin: 10px;
  display: inline-block;
  width: 200px;
}

.stock-main-text {
  display: block;
  font-weight: 500;
  font-size: 16px;
}

.curcle-lab {
  background: #2fc26e;
  height: 10px;
  width: 10px;
  display: inline-block;
  margin-right: 10px;
  border-radius: 10px;
}

.state-GREY {
  color: #6d7986;
  font-weight: bold;
  display: block;
}

.state-RED {
  color: #ef3124;
  font-weight: bold;
}

.state-GREEN {
  color: #2fc26e;
  font-weight: bold;
}

.about-company {
  &__right-block {
    padding-top: 20px;
    padding-left: 30px;
  }

  &__content-block {
    &__title {
      margin: auto 0;
      padding: 0;
      font-weight: 400;
      padding-bottom: 35px;
      padding-left: 25px;
    }

    &__analysts {
      width: 100%;

      div {
        display: flex;
      }
    }
  }
}

.plate_type_default-no-shadow {
  box-shadow: none;
}

.tab-content {
  padding-top: 36px;

  &__detail {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: left;
    align-items: flex-start;

    &__company-info {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: left;
      align-items: flex-start;

      .company-logo {
        width: 280px;
      }

    }

    &__company-charts {
      display: contents;

      img {
        width: 100%;
      }
    }

    &__text {
      flex: 1;
      padding-left: 36px;
    }

    h4 {
      font-size: 24px;
      font-weight: 400;
      padding-bottom: 21px;
      line-height: 48px;
      color: $dark_indigo;
      padding: 15px 0;
    }
  }
}

.pl0 {
  padding-left: 0px;
}

.no-margin-product .filter-sidebar__item {
  padding: 0;
}

.company .recharts-rectangle:hover {
  fill: red !important;
  cursor: pointer;
}


.wordbreak {
  word-break: break-all;
}

.margin-bottom-chart {
  margin-bottom: 40px;
}

.btn-home-20 {
  margin-top: 20px;
}

.cover-list-m {
  margin-bottom: 30px;
}

.prof-border {
  border-bottom: 1px solid #dbdee1;
}

.prof-radio .radio {
  margin-right: 10px;
}

.user-form__setting {
  display: flex;
  flex-direction: row;
  padding: 25px 0;
}

.margin-bottom-15 {
  margin-bottom: 15px !important;
}

.margin-top-15 {
  margin-top: 15px !important;
}

.custom-flex {
  flex: 1.6 1;
}

.cursor {
  cursor: pointer;
}

.container{
  min-height: 100%;
  margin: 0 auto;
  padding: 15px 70px;
  max-width: 1920px;
}

.empty-data{
  text-align: center;
  margin: auto;
  display: block;
  font-size: 18px;
}

.empty-data2{
  text-align: center;
  margin-top: 64px;
  display: block;
  font-size: 18px;
}

.report-mar{
  padding-top: 40px;
}
.padding-top-0{
  padding-top: 0px;
}

.paddding-margin-none{
  padding: 0px;
  margin: 0px;
}

.sector-bottom{
  margin-bottom: 36px;
}

.img-search{
  width: 150px;
}
.author-search-img {
  width: 282px;
}

.no-pointer{
  cursor: default;
}

.pointer{
  cursor: pointer;
}

.price-status {
  &--down {
    color: #ef3124;
    font-weight: bold;
  }
  &--up {
    color: #2fc26e;
    font-weight: bold;
  }
  &--default {
    color: #6d7986;
    font-weight: bold;
  }
}

.custom-filter-section {
  max-width: 284px;
}

.custom-column-section {
  min-width: 284px;
  padding: 0;
}

.button_brand {
  color: $red_brand;
  border-color: $red_brand;

  &:hover {
    border-color: $red_brand;
  }
}

.button_icon {
  .button__text {
    display: flex;
    align-items: center;

    svg {
      margin-right: 5px;
    }
  }
}

.flex_no-wrap {
  flex-wrap: nowrap;
}

.custom-page-title {
  margin-left: 280px;
  font-weight: 400;
}

.custom-margin-bottom {
  margin-bottom: 30px;
}

.button_default {
  background-color: transparent;
  border-width: 1px;
}

.ticker {
    //width: 436px;
    &__row {
        font-size: 18px;
        //display: flex;
        padding: 5px 0;
        cursor: default;
        &_type_head {
            opacity: 0.7;
        }
    }
    &__cell {
        width: 198.625px;
        table-layout: fixed;
        //flex: 1;
        //&:last-child {
        //    flex: 0.2;
        //}
        &.company-name {
            font-weight: bold;
        }
    }
}
.research-content {
    &__top {
        margin-bottom: 13px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-size: 18px;
        line-height: 36px;
        flex-direction: row;
        &_title {
            padding-bottom: 26px;
            flex: 1;
            .name-section {
                display: block;
                padding-bottom: 26px;
                font-weight: 400;
                margin: 0;
            }
        }
        &_file {
            padding: 0;
            svg {
                width: 60px;
                height: 60px;
            }
        }
    }

    &__title {
        margin-bottom: 40px;
        h3 {
            font-weight: bold;
            font-size: 30px;
            line-height: 36px;
        }
        &_date {
            font-size: 18px;
            line-height: 36px;
        }
    }

    &__analysts {
        display: flex;
        // justify-content: space-between;
        margin-bottom: 40px;
    }

    &__text {
        margin-bottom: 25px;
    }

    &__companies {
        ul {
            margin: 0;
            padding: 0;
            li {
                position: relative;
                display: inline;
                margin-right: 5px;
                padding: 3px;
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
                padding: 9px 12px;
                &::before {
                    background: rgba(11, 31, 53, 0.95);
                    content: '';
                    display: inline-block;
                    position: absolute;
                    border-radius: 50%;
                    top: 17px;
                    width: 6px;
                    height: 6px;
                    margin-left: -14px;
                }
            }
        }
    }

}
@import '../../styles/variables';

.accordion__section {
	display: flex;
	flex-direction: column;
	margin-bottom: 16px;

	.accordion {
		background-color: $dark_indigo_05;
		color: $dark_indigo_60;
		cursor: pointer;
		padding: 18px;
		display: flex;
		align-items: center;
		border: none;
		outline: none;
		border-bottom: 1px solid $dark_indigo_15;
		border-radius: 4px 4px 0 0;
		padding: 18px 12px 16px 12px;
		
		&--mod {
			&_is-open {
				background-color: $dark_indigo_05;
			}
			&_is-close {
				background-color: $dark_indigo_05;
			}
		}
		&:hover {
		  background-color: $dark_indigo_15;
		}

		&__title {
			font-size: 16px;
			line-height: 22px;
			color: $dark_indigo_60;
			text-overflow: ellipsis;
    		white-space: nowrap;
		}

		&__icon {
			margin-left: auto;
			transition: transform 0.6s ease;
			background-image: url('../../assets/icons/icon_arrow-down.svg');
			width: 24px;
			height: 24px;
		}

		&__icon-close {
			margin-left: auto;
			transition: transform 0.6s ease;
			background-image: url('../../assets/icons/icon_arrow-up.svg');
			width: 24px;
			height: 24px;
		}

		&__content {
			background-color: #ffffff;
			overflow: hidden;
			box-shadow: 0px 1px 2px $dark_indigo_15, 0px 4px 12px rgba(11, 31, 53, 0.08);
			transition: max-height 0.6s ease;
			border-radius: 0 0 8px 8px;
			border-top: 1px solid $dark_indigo;

			&--mod {
				&_is-hide {
					display: none;
				}
				&_is-show {
					display: block;
				}
			}
		}
	}
	
}

  
//   .accordion
  
//   .accordion__icon {
//     margin-left: auto;
//     transition: transform 0.6s ease;
//     background-image: url('../../assets/icons/icon_arrow-down.svg');
//     width: 24px;
//     height: 24px;
//   }
//   .accordion__icon-close {
//     margin-left: auto;
//     transition: transform 0.6s ease;
//     background-image: url('../../assets/icons/icon_arrow-up.svg');
//     width: 24px;
//     height: 24px;
//   }

//   .accordion
  
//   .accordion__text {
//     font-family: "Open Sans", sans-serif;
//     font-weight: 400;
//     font-size: 14px;
//     padding: 18px;
//   }
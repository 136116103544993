@import '../../../../styles/variables';

.checkbox__text {
  color: #6d7986;
}

.mr-res {
  margin-left: 28px;
  margin-top: 7px;
  color: rgb(11, 31, 53);
}

.field-control-pa {
  margin-top: 10px;
  font-size: 13px;

  .tel-pa {
    color: #ef3124;
    font-size: 13px;
    line-height: 22px;
    letter-spacing: 0.7px;
    font-weight: bold;
  }
}

.login-form {

  &__title {
    padding-bottom: 10px;
    font-size: 16px;
    font-weight: 400;
  }

  .field-control {
    padding: 10px 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    label {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: $dark_indigo_60;
    }

    &__section {
      flex: 1;
      padding-right: 20px;
      text-align: center;
    }

    .help-information {
      padding-top: 20px;
      font-size: 13px;

      span {
        color: $red_brand;
        font-size: 13px;
        line-height: 22px;
        letter-spacing: 0.7px;
        font-weight: bold;
      }
    }

  }

  .login-form__input {
    width: 100%;
    border: none;
    padding: 15px 13px;
    background: #F1F3F5;
    border-radius: 5px;
    outline: none;
    font-size: 18px;

    &::placeholder {
      color: rgba(10, 30, 50, 0.3);
    }
  }

  .link-restore {
    text-align: right;
  }
}
@import '../../styles/variables';

$speed: 0.6s;
$delay: ($speed * .5);
$easing: cubic-bezier(.55,0,.1,1);

body.modal-visible {
    overflow: hidden;
}

.modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 1001;
    overflow: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    padding-top: 24px;
    padding-bottom: 24px;

    opacity: 0;
    z-index: 1001;
    visibility: hidden;
    transition: opacity $speed $easing, visibility $speed $easing;

    .mobile {
        display: none;
    }

    .modal {
        position: relative;
        box-sizing: border-box;
        width: 800px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        flex: 0 0 auto;
        border-radius: 8px;
        padding: 54px 72px;
        border: 0;
        background: #fff;
        overflow: auto;
        margin: auto;
        visibility: hidden;
        transform: translateY(0px);
        transition: transform 200ms ease-out 0s;
        
        &__container {
            width: 100%;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            text-align: left;

            .modal__content {
                -ms-flex: 1 1 auto;
                flex: 1 1 auto;
            }

        }

        &__close {
            position: absolute;
            top: 0;
            right: 0;
            width: 100%;
            height: 48px;
            transition: border-bottom 0.4s;
            transition: opacity $speed $easing, transform $speed $easing;
            transition-delay: $delay;

            button {
                position: absolute;
                top: 0;
                right: 0;
                width: 60px;
                height: 42px;
                opacity: .6;
                background: transparent;
                border: none;
                padding: 0;
                margin: 0;
                transition: opacity .1s ease-out;
                cursor: pointer;
                outline: none;
            }
        }

        .pointer,
        .not-allowed {
            font-size: 0;
        }
    }

    body.modal-visible {
        position: fixed;
        overflow: hidden;
    }

    @media screen and (max-width: $mobile-screen) {
        .modal {
            // padding: 0 !important;

            .mobile {
                display: block;
            }

            .modal__content {
                padding-top: 48px;

                .event-info-content {
                    .event-info-content__top {
                        flex-direction: column;
                        align-items: flex-start;
                        margin-bottom: 12px;
                    }

                    .event-info-content__top_title {
                        padding-bottom: 0;
                        h2 {
                            font-size: 20px;
                            line-height: 1.3;
                        }
                    }

                    .event-info-content__info  {
                        p {
                            margin-bottom: 12px;
                            padding: 0;
                        }
                    }
                }

                .label-tag {
                    font-weight: 400;
                    display: inline;
                }

                .research-content__row {
                    margin-bottom: 12px;

                    & + .research-content__analysts {
                        margin-bottom: 0;
                    }
                }

                .research-content__text {
                    padding-top: 10px;
                    padding-bottom: 10px;
                    border-top: 3px solid rgba(109, 121, 134, 0.4);
                    border-bottom: 3px solid rgba(109, 121, 134, 0.4);

                    .paragraph {
                        margin-bottom: 0;
                    }
                }

                .research-content__top {
                    .research-content__top_file {
                        display: none;
                    }
                }

                .research-content__top_title {
                    padding-bottom: 0;
                }

                .research-content__top_file {
                    .pointer,
                    .not-allowed {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 100%;
                        background-color: transparent;
                        border-radius: 4px;
                        outline: none;
                        padding: 12px;
                        font-size: 16px;
                        line-height: 1.3;
                        font-weight: 600;

                        svg {
                            width: 20px;
                            height: 20px;
                            margin-right: 5px;
                        }
                    }

                    .pointer {
                        border: 1px solid $red_brand;
                        color: $red_brand;
                    }

                    .not-allowed {
                        border: 1px solid $dark_indigo;
                        color: $dark_indigo;
                        opacity: 0.3;
                    }
                }

                .research-content__close {
                    button {
                        width: 100%;
                        display: block;
                        background-color: transparent;
                        border-radius: 4px;
                        outline: none;
                        border: 1px solid $red_brand;
                        color: $red_brand;
                        // border: 1px solid $dark_indigo;
                        // color: $dark_indigo;
                        padding: 11px;
                        font-size: 16px;
                        line-height: 1.3;
                        font-weight: 600;
                    }
                }

                .research-content__analysts {
                    margin-right: -12px;
                    .user-cart {
                        width: 100%;
                    }

                    .link {
                        display: block;
                        width: 100%;
                        padding: 12px;
                        padding-left: 0;
                        border-bottom: 1px solid rgba(11, 31, 53, 0.1);
                    }

                    .user-info {
                        padding: 0;
                        .user-info__detail {
                            .fullname {
                                font-size: 16px;
                                line-height: 1.3;
                                font-weight: 400;
                            }
                        }
                        .user-info__photo {
                            margin-right: 8px;
                            img {
                                width: 36px;
                                height: 36px;
                            }
                        }
                    }
                }

                .research-content__title {
                    h3 {
                        font-size: 20px;
                        line-height: 1.3;
                        font-weight: 600;
                        margin-bottom: 12px;
                    }

                    .research-content__title_date {
                        font-size: 12px;
                        line-height: 1.3;
                        color: rgba(109, 121, 134, 1);
                    }
                }

                .name-section {
                    font-weight: 600;
                    padding-bottom: 12px;
                }
            }
        }
    }    
}

@import '../../styles/variables';

.user-cart {
    display: flex;
    flex-direction: row;
        
    .user-info {
        display: flex;
        flex-direction: row;
        padding: 15px;
        border-radius: 4px;
        border: 1px solid #ffffff;
        cursor: pointer;
        flex: 1;

        &_border_hover {
            &:hover {
                border: 1px solid #dbdee1;
            }
        }

        &__photo {
            margin-right: 36px;
            img {
                width: 92px;
                height: 92px;
            }
            &_type_analyst,
            &_type_analyst-group {
                img {
                    border-radius: 50%;
                    width: 72px;
                    height: 72px;
                }
            }
        }

        &__detail {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex-wrap: wrap;
            span {
                display: block;
                font-size: 18px;
            }
            .fullname {
                font-weight: 600;
                line-height: 25px;
            }
            .department {
                font-weight: 400;
                line-height: 25px;
                color: #233547;
            }
            .post {
                font-weight: 400;
                line-height: 25px;
                color: #6d7986;
            }

            &_data_group {
                flex-direction: row;
                align-items: center;
                justify-content: right;
            }

            &.valign-top {
                justify-content: end;
            }

        }
        &__detail-group {
            padding-left: 25px;
            flex: 0.7;
        }
    }
    
}
.chart {
    display: block;
    border-radius: 8px;
    padding: 1px 33px 13px 33px;
    margin-bottom: 35px;
    cursor: default;
}

.plateq {
    display: inline-block;
    position: relative;
    margin-right: 7px;
    margin-bottom: 7px;
    padding: 17px 30px 0px 30px;
    border-radius: 8px;
    box-shadow: 0 6px 14px 0 rgba(11, 31, 53, 0.15);
}
.chart-padding-bottom{
    margin-bottom: 40px;
    margin-top: 60px;
}

.chart-padding-bottom-economic {
    margin-bottom: 40px;
    margin-top: -15px;
}

.text-t{
    font-size: small;
    font-weight:bold;
    //font-weight: bold;
    //font-size: 12px;
}
/* Основные цвета в интерфейсе */
$dark_indigo: #0b1f35;
$dark_indigo_60: #6d7986;
$dark_indigo_30: #b6bcc3;
$dark_indigo_15: #dbdee1;
$dark_indigo_05: #f3f4f5;
$red_brand: #ef3124;
$red_brand_85: #f15045;
$red_error: #ff5c5c;
$green_done: #2fc26e;

$border_plate: #e8e8e8;
$border_menu: #0a1e32;

$border: 1px solid rgba(11, 31, 53, 0.1);
$borderColor: rgba(11, 31, 53, 0.1);

/** Отступы */
$no_padding: 0;
$padding_40: 40px;
$padding_45: 45px;
$padding_60: 60px;
$padding_65: 65px;
$padding_70: 70px;
$padding_75: 75px;
$padding_80: 80px;
$padding_85: 85px;

// $mobile-screen: 767px;
$mobile-screen: 639px;
$table-screen: 1023px;
@import '../../../styles/variables';

.economics-page {
    .table {
        padding-left: 12px;
        padding-right: 12px;
        width: 100%;
    }

    @media screen and (max-width: $mobile-screen) {
        .table {
            padding-left: 0;
            padding-right: 0;
        }    
    }
}

.future-events-page {
    @media screen and (max-width: $mobile-screen) {
        .table {
            .table__wrapper {
                .table__body {
                    .table__cell {    
                        margin-bottom: 0;
                        padding: 10px 0;

                        &.pdf.undefined {
                            display: none;
                        }
                    
                        &:nth-child(1) {
                            order: 3;
                            border-bottom: 1px solid rgba(11, 31, 53, 0.1);
                            width: 100%;

                            .cell-wrapper-title {
                                display: block;
                            }
                        }
                        &:nth-child(2) {
                            order: 1;
                        }
                        &:nth-child(3) {
                            order: 4;
                            border-bottom: 1px solid rgba(11, 31, 53, 0.1);
                            width: 100%;

                            .cell-wrapper-title {
                                display: block;
                            }
                        }
                        &:nth-child(4) {
                            order: 2;
                            font-size: 17px;
                            line-height: 1.3;
                            font-weight: 600;
                            color: rgba(11, 31, 53, 1);
                        }
                        &:nth-child(5) {
                            order: 5;
                            width: 100%;
                            margin-top: 10px;

                            .cell-wrapper-title {
                                display: none;
                            }
                        }
                    }
                }
            }
        }    
    }
}

.equity-research-page,
.fixed-income-page {
    @media screen and (max-width: $mobile-screen) {
        .table {
            .table__wrapper {
                .table__body {
                    .table__cell {    
                        &:nth-child(1) { order: 3; font-size: 15px; }
                        &:nth-child(2) { order: 1; }
                        &:nth-child(3) { order: 4; margin-bottom: 0; }
                        &:nth-child(4) { order: 2; }
                    }
                }
            }
        }    
    }
}

.company-inner,
.team-member-inner-page,
.search-result-page,
.custom-page-wrapper {
    @media screen and (max-width: $mobile-screen) {
        .table {
            .table__wrapper {
                flex-direction: column;

                .table__body {
                    margin-right: 0;
				    margin-bottom: 16px;
                    padding: 16px 12px;
                }
            }
        }    
    }
}

.economics-page,
.commodities-page {
    @media screen and (max-width: $mobile-screen) {
        .table {
            .table__wrapper {
                .table__body {
                    .table__cell {    
                        &:nth-child(1) {
                            order: 2;
                            font-size: 15px;
                            line-height: 1.3;
                        }
                        &:nth-child(2) {
                            order: 3;
                            font-size: 12px;
                            line-height: 1.3;
                            margin-bottom: 0;
                        }
                        &:nth-child(3) {
                            order: 1;
                            font-size: 12px;
                            line-height: 1.3;
                            color: rgba(109, 121, 134, 1);
                        }
                        &:nth-child(4) { order: 4; }
                    }
                }
            }
        }    
    }
}

.companies-page {
    @media screen and (max-width: $mobile-screen) {
        .table {
            .table__wrapper {
                .table__body {
                    padding: 0;
                    .table__cell {   
                        margin-bottom: 0;

                        .cell-wrapper {
                            padding-top: 10px;
                            padding-bottom: 10px;
                            padding-right: 12px;
                            padding-left: 12px;
                        }

                        .company-custom-name {
                            display: flex;
                            align-items: center;

                            .image-wrapper {
                                max-width: 36px;
                                margin-right: 16px;
                            }
                        }

                        &:nth-child(2),
                        &:nth-child(3),
                        &:nth-child(4),
                        &:nth-child(5) {
                            padding-left: 10px;
                            .cell-wrapper {
                                padding-left: 0;
                                border-bottom: 1px solid rgba(11, 31, 53, 0.1);
                            }
                            .cell-wrapper-title {
                                display: block;
                                margin-bottom: 3px;
                            }
                            .cell-wrapper-body {
                                font-size: 16px;
                                line-height: 1.3;
                                color: $dark_indigo;
                                font-weight: 400;
                            }
                        }
                        &:nth-child(1) {
                            order: 1;
                            font-size: 17px;
                            font-weight: 600;
                            line-height: 1.3;
                        }
                        &:nth-child(2) {
                            order: 2;
                        }
                        &:nth-child(3) { order: 3; }
                        &:nth-child(4) { order: 4; }
                        &:nth-child(5) { order: 5; }
                        &:nth-child(6) {
                            order: 6;
                            font-size: 13px;
                            line-height: 1.3;
                        }
                    }
                }
            }
        }    
    }

    @media screen and (min-width: $mobile-screen + 1px) {
        .company-custom-name {
            .image-wrapper {
                display: none;
            }
        }
    }
}

.table {
    display: flex;
    flex-flow: column nowrap;
    flex: 1 1 auto;
    font-size: 14px;
    // max-width: 100%;

    .cell-wrapper {
        display: flex;
        flex-direction: column;

        .cell-wrapper-title {
            font-size: 12px;
            line-height: 1.3;
            color: rgba(109, 121, 134, 1);
            font-weight: 400;
        }
        .cell-wrapper-body {
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 5;
            -webkit-box-orient: vertical;
        }
    }

    &__caption {
        line-height: 60px;
        color: $dark_indigo;
        &_size_default {
            font-size: 24px;
        }
        &_size_large {
            font-size: 48px;
            padding-bottom: 30px;
        }
    }

    &__head {
        display: flex;
        cursor: pointer;
        white-space: nowrap;
    }

    &__body {
        display: flex;
        border-bottom: 1px solid #F3F4F5;
        align-items: center;
        cursor: pointer;
        &:hover {
            box-shadow: 0px 1px 5px rgba(10, 30, 50, 0.2);
        }
    }

    &__row {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        
    }

    &__cell {
        flex-basis: 0;
        // word-break: break-word;
        overflow: hidden;
        min-width: 0px;
        padding: 11px;

        &_header {
            font-size: 14px;
            justify-content: end;
            color: #546271;
            white-space: nowrap;
        }

        &.is-icon {
            padding: 4px 10px;
            svg {
                width: 27px;
                height: 27px;
            }
        }


        &_body {
            color: $dark_indigo;
            opacity: 0.95;
            font-size: 14px;
            color: $dark_indigo;
        }

        &_size_xs {
            flex-grow: 0.2;
        }
        &_size_s {
            flex-grow: 0.5;
        }
        &_size_m {
            flex-grow: 1.2;
        }
        &_size_l {
            flex-grow: 3;
        }
        .sort-icon {
            width: 24px;
            height: 24px;
            padding: 3px 20px;
            &_sort_asc {
                background-image: url('../../../assets/icons/icon_arrow-top.svg');
                background-repeat: no-repeat;
                &:hover {
                    background-image: url('../../../assets/icons/icon_arrow-top_color.svg');
                }
            }
            &_sort_desc {
                background-repeat: no-repeat;
                background-image: url('../../../assets/icons/icon_arrow-bottom.svg');
                &:hover {
                    background-image: url('../../../assets/icons/icon_arrow-bottom_color.svg');
                }
            }
        }

    }

    &__footer {
        padding: 4px;
        background: #f8f8f8;
        z-index: -1;
    }

    &__table-link {
        span {
            span {
                border: none;
            }
        }
    }

    @media screen and (max-width: $mobile-screen) {
        width: 100%;
        max-width: 100%;
        .table__wrapper {
            overflow-x: auto;
            display: flex;
            flex-direction: row;
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */

            /* Hide scrollbar for Chrome, Safari and Opera */
            &::-webkit-scrollbar {
                display: none;
            }

            .table__body {
                flex-direction: column;
                align-items: flex-start;
                background-color: rgba(251, 251, 251, 1);
                border: 1px solid rgba(243, 244, 245, 1);
                min-width: 215px;
                margin-right: 16px;
                border-radius: 8px;
                padding: 12px;
    
                &:hover {
                    box-shadow: none;
                }
    
                .table__cell {
                    flex-grow: initial;
                    overflow: initial;
                    padding: 0;
                    margin-bottom: 10px;
                    width: 100%;

                    .author-table-photo-wrapper {
                        display: flex;
                        align-items: center;
                        padding-top: 6px;
                        padding-bottom: 10px;

                        span {
                            padding-left: 8px;
                        }
                    }

                    .author-table-photo {
                        width: 16px;
                        height: 16px;
                        border-radius: 50%;

                        &:not(:first-child) {
                            margin-left: -6px;
                        }
                    }

                    &.pdf {
                        padding: 8px 12px;
                        border: 1px solid $red_brand;
                        color: $red_brand;
                        border-radius: 4px;
                        text-align: center;
                        width: 100%;

                        .pdf-icon-t {
                            display: flex;
                            margin-right: 5px;
                            svg {
                                width: 20px;
                                height: 20px;
                            }
                        }

                        .cell-wrapper {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;
                            font-size: 15px;
                            line-height: 1.3;
                        }

                        &.not-allowed {
                            border: 1px solid $dark_indigo;
                            color: $dark_indigo;
                            opacity: 0.2;
                            cursor: not-allowed;
                        }
                    }

                    .cell-wrapper-title {
                        display: none;
                    }
        
                    &.table__cell--action {
                        background-color: transparent;
                        color: $red_brand;
                        border: 1px solid $red_brand;
                        padding: 8px;
        
                        .button__text {
                            display: flex;
                            align-items: center;
        
                            & > div {
                                margin-right: 5px;
                            }
                        }
                    }
                    
                    &:nth-child(1) { order: 4; }
                    &:nth-child(2) {
                        order: 2;
                        font-size: 15px;
                        line-height: 1.3;
                    }
                    &:nth-child(3) {
                        order: 3;
                        flex-grow: 1;
                        font-size: 13px;
                        line-height: 1.3;
                    }
                    &:nth-child(4) {
                        order: 1;
                        font-size: 12px;
                        line-height: 1.3;
                        color: rgba(109, 121, 134, 1);
                    }
                }
            }
        }

        .table__head {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            overflow-x: auto;
            -ms-overflow-style: none;  /* IE and Edge */
            scrollbar-width: none;  /* Firefox */

            /* Hide scrollbar for Chrome, Safari and Opera */
            &::-webkit-scrollbar {
                display: none;
            }

            .table__cell {
                position: relative;
                overflow: initial;
                flex-basis: auto;
                width: auto;
                padding: 30px 0;
                flex-grow: initial !important;
                padding-right: 40px;
                font-size: 16px;
                line-height: 1.3;
                flex-shrink: 0;
                opacity: 0.6;

                &.active {
                    opacity: 1;
                    color: rgba(11, 31, 53, 1);
                }

                .sort-icon {
                    position: absolute;
                    right: 12px;
                    top: 28px;
                    padding: 3px 12px;

                    &.sort-icon_sort_asc:not(&.active) {
                        transform: scaleY(-1);
                    }

                    &.active {
                        background-image: url('../../../assets/icons/icon_arrow-bottom_color.svg');
                    }
                }
            }
        }

        .table__caption {
            font-size: 28px;
            line-height: 1.3;
            font-weight: 700;
        }
    }

    // Hardcode.
    // @TODO: refactoring
    @media screen and (min-width: $mobile-screen + 1px) {
        .author-table-photo-wrapper {
            display: none;
        }

        .cell-wrapper-title {
            display: none;
        }

        .table__head {
            .table__cell {
                .sort-icon {
                    &:not(&.active) {
                        display: none;
                    }

                    &.active {
                        display: inline;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: $mobile-screen) {
    .label-tag {
        font-weight: 500;
    }
    .custom-page-wrapper {
        .table__wrapper {
            .table__body {
                .table__cell {
                    &:nth-child(1) {
                        order: 4;
                    }
                    &:nth-child(2) {
                        order: 1;
                        font-size: 15px;
                        line-height: 1.3;
                    }
                    &:nth-child(3) {
                        order: 3;
                        font-size: 13px;
                        line-height: 1.3;
                    }
                    &:nth-child(4) {
                        order: 2;
                        font-size: 12px;
                        line-height: 1.3;
                        color: rgba(109, 121, 134, 1);
                    }

                    // &.button {
                    //     display: none;
                    // }
                }
            }
        }
    }
}
@import '../../styles/variables';

.user-navigation {
  display: flex;
  flex-direction: row;
  flex-wrap: unset;
  padding: 12px 20px;
  justify-content: flex-end;
  color: #0b1f35;
  text-decoration-color: rgb(11, 31, 53);

  &__profile {
    margin-right: 16px;
    font-size: 16px;
    background-image: url('../../assets/icons/icon_user_default.svg');
    background-repeat: no-repeat;
    background-position: left;
    padding-left: 28px;

    &:hover {
      color: $red_brand;
      background-image: url('../../assets/icons/icon_user_color.svg');
    }
  }

  &__logout {
    height: 24px;
    background-image: url('../../assets/icons/icon_logout_default.svg');
    width: 24px;

    &:hover {
      background-image: url('../../assets/icons/icon_logout_color.svg');
    }
  }
}
@import '../../styles/variables';

.employee {
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;

    &__contact-info {
        flex: 1;
        padding-right: 15px;
        span {
            display: block;
        }
        .employee-name {
            font-weight: bold;
            font-size: 30px;
            line-height: 36px;
            margin: 0;
        }
        .employee-post {
            font-weight: normal;
            font-size: 18px;
            line-height: 36px;
        }

        .employee-email {
            margin-top: 48px;
            margin-bottom: 20px;
            font-size: 16px;
            line-height: 22px;
        }

        .employee-other_info {
            font-size: 16px;
            line-height: 22px;
            margin-bottom: 20px;
            span {
                display: block;
            }
        }
    }
    &__photo {
        img {
            width: 240px;
            height: 240px;
        }
    }

    @media screen and (max-width: $mobile-screen) {
        margin-bottom: 20px;

        .employee__contact-info {
            order: 2;

            .employee-name {
                font-size: 17px;
                line-height: 1.3;
                font-weight: 600;
            }

            .employee-post {
                font-size: 15px;
                line-height: 1.3;
                font-weight: 400;
                color: rgba(109, 121, 134, 1);
            }
        }

        .employee__photo {
            order: 1;
            width: 44px;
            height: 44px;
            border-radius: 50%;
            border: 1px solid rgba(109, 121, 134, 0.4);
            overflow: hidden;
            margin-right: 16px;

            img {
                max-width: 100%;
                height: auto;
            }
        }
    }
}

@media screen and (max-width: $mobile-screen) {
    .employee-more-info {
        margin-right: -12px;
        margin-bottom: 24px;

        .employee-email,
        .employee-other_info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding-top: 12px;
            padding-bottom: 12px;
            border-bottom: 1px solid rgba(11, 31, 53, 0.1);
            font-size: 16px;
            line-height: 1.3;
            font-weight: 400;

            .title {
                color: rgba(109, 121, 134, 1);
                font-size: 12px;
                line-height: 1.3;
                font-weight: 400;
            }
        }
    }
}

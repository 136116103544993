@import './variables';

@media screen and (min-width: $mobile-screen + 1px) {
	// Temporary approach while tablet styles are not ready!
	#root {
		min-width: 1200px;
	}

	.custom-bar-chart-wrapper {
		.custom-bar-chart {
			overflow-x: hidden !important;
		}

		.recharts-responsive-container {
			width: 100% !important;
		}
	}

	.lowfile {
		.svg-icon {
			display: none;
		}
	}
}

@media screen and (max-width: $mobile-screen) {
	// Hardcode! @TODO: Html structure refactoring needed!
	.team-page {
		padding-top: 8px;
		overflow: hidden;
		margin-left: -12px;
		margin-right: -12px;
	}

	.custom-bar-chart-wrapper {
		overflow-x: hidden;

		.recharts-responsive-container {
			min-width: 100%;
		}
	}

	.team-member-inner-page {
		padding-top: 32px;

		.team-member-row {
			.grid-col {
				&:nth-child(1) { order: 1; }
				&:nth-child(2) { order: 4; }
				&:nth-child(3) { order: 2; }
				&:nth-child(4) { order: 3; }
			}
		}

		.filter-sidebar__item.announcements {
			border-bottom: none;
		}

		.table {
			.table__body {
				.table__cell {
					&:nth-child(1) {
						order: 2;
						font-size: 15px;
						line-height: 1.3;
					}
					&:nth-child(2) {
						order: 3;
						font-size: 13px;
						line-height: 1.3;
						margin-bottom: 0;

						.cell-wrapper-title {
							display: block;
							margin-bottom: 5px;
						}
					}
					&:nth-child(3) {
						order: 1;
						font-size: 12px;
						line-height: 1.3;
						color: rgba(109, 121, 134, 1);
						font-weight: 400;
					}
				}
			}
		}
	}

	.information-page {
		margin-left: -12px;
		margin-right: -12px;

		.lowfile-container {
			display: flex;
			flex-direction: column;
			padding-left: 12px;
			margin-top: 24px;

			.lowfile-item {
				width: 100%;
				padding-top: 12px;
				padding-bottom: 12px;
				padding-right: 12px;
				border-bottom: $border;

				.lowfile {
					display: flex;
					align-items: center;
					font-size: 16px;
					line-height: 1.3;
					font-weight: 400;
					border: none;

					.svg-icon {
						display: flex;
						margin-right: 12px;
						width: 20px;
					}
				}
			}
		}
	}

	.lang-bar {
		a {
			border: 1px solid rgba(243, 244, 245, 1);
			display: flex;
			align-items: center;
			justify-content: center;
			width: 36px;
			height: 36px;

			.link__text-container {
				flex: initial;
			}
		}
	}

	.custom-login-page {
		button {
		  	height: 44px;
		}
	}

	body.modal-open {
		overflow: hidden;

		.lang-bar {
			a {
				border: none;
			}
		}
		.header {
			background-color: #fff;
			position: relative;
			z-index: 1000000000;
		}
		#root {
			& > .container {
				&:after {
					z-index: 200;
					content: "";
					display: block;
					position: absolute;
					top: 110px;
					bottom: 0;
					left: 0;
					right: 0;
					background-color: rgba(11, 31, 53, 0.4);
					transition: background-color 0.15s ease;
				}
			}
		}
	}

	.home-page {
		padding-top: 30px;

		.home-page__wrapper {
			padding-top: 30px;
			.announcements {
				border-bottom: none;
				padding-left: 12px;
				padding-right: 12px;
			}
		}
	}

	#root {
		min-width: 0;
	}

	.container{
		padding: 15px 12px;
		padding-top: 0;
		min-height: 0;
		padding-bottom: 32px;
	}

	.login-form {
		&__title {
			margin-bottom: 30px;
			padding-bottom: 0;
			font-size: 28px;
			line-height:1.3;
			font-weight: 700;
		}
	}

	.chart-padding-bottom {
		margin-bottom: 20px;
		margin-top: 0px;
	}

	.plateq {
		display: inline-block;
		position: relative;
		margin-right: 0;
		margin-bottom: 30px;
		padding: 0;
		border-radius: 4px;
		box-shadow: none;

		.heading {
			font-size: 30px;
			line-height:1.3;
			margin-bottom: 16px;
		}
	}

	.header {
		padding: 0;
		height: auto;

		.header__content {
			position: relative;
			padding: 0;
		}

		.menu-wrapper {
			border-bottom: none;
			padding: 16px 12px;
			justify-content: flex-end;
			&__menu {
				li {
					display: block;
				}
			}
		}
	}

	.analyst-profile {
		flex-direction: column;
		justify-content: flex-start;
		margin-top: 0;

		.analyst-profile__photo {
			padding: 0;
			flex: initial;
			margin-right: 16px;

			img {
				width: 44px;
				height: 44px;
				border: 1px solid rgba(109, 121, 134, 0.4);
				border-radius: 50%;
			}
		}

		.analyst-profile__contact {
			padding: 0;
		}

		.analyst-profile__contact__fullname {
			font-size: 17px;
			line-height: 1.3;
			font-weight: 600;
		}

		.analyst-profile__contact__post {
			font-size: 15px;
			line-height: 1.3;
			margin-bottom: 0;
		}
	}

	.actual-custom {
		.filter-sidebar__item {
			padding-top: 0;
			padding-left: 12px;
			padding-right: 12px;
		}
	}

	.table-custom {
		margin-bottom: 30px;

		.table__wrapper {
			margin-bottom: 24px;
		}
	}

	.filters-custom {
		margin-bottom: 24px;

		form {
			width: 100%;
		}
	}

	.standart-table-m {
		margin-top: 0;
	}

	.coverage-list {
		padding: 0;

		.coverage-detail {
			flex-direction: column;
			justify-content: flex-start;
			margin-left: -12px;
			margin-right: -12px;
		}

		.coverage-list__caption {
			font-size: 22px;
			line-height: 1.3;
			font-weight: 700;
		}

		.coverage-list__row-head {
			display: none;
		}

		.coverage-detail__column--current {
			margin-bottom: 12px;
			padding-left: 12px;
		}
	}

	.target-price-column {
		.table__head {
			display: none;
		}

		.table__wrapper {
			margin-top: 32px;
			margin-left: -12px;
			margin-right: -12px;

			.table__body {
				display: flex;
				flex-wrap: wrap;
				flex-direction: row;
				background-color: transparent;
				border: none;
				border-radius: 0;
				box-shadow: none;
				padding-top: 0 !important;
				padding-bottom: 0 !important;

				&:nth-child(odd) {
					background-color: rgba(251, 251, 251, 1);
				}

				.table__cell {
					width: 50%;
					flex-basis: 50%;
					margin-bottom: 5px;

					&:nth-child(1) {
						order: 3;
						margin-bottom: 8px;

						.cell-wrapper-body {
							font-size: 12px;
							line-height: 1.3;
							font-weight: 500;
							color: rgba(109, 121, 134, 1);
						}
					}
					&:nth-child(2) {
						order: 1;
						padding-top: 8px;

						.cell-wrapper-body {
							font-size: 16px;
							line-height: 1.3;
							font-weight: 600;
						}
					}
					&:nth-child(3) {
						order: 2;
						text-align: right;
						padding-top: 8px;

						.cell-wrapper-body {
							font-size: 16px;
							line-height: 1.3;
							font-weight: 600;
						}
					}
					&:nth-child(4) {
						order: 4;
						text-align: right;
						margin-bottom: 8px;

						.cell-wrapper-body {
							font-size: 12px;
							line-height: 1.3;
							font-weight: 500;
							color: rgba(109, 121, 134, 1);
						}
					}
				}
			}
		}
	}

	.coverage-detail__column__row {
		display: flex;
		flex-wrap: wrap;

		&:nth-child(odd) {
			background-color: rgba(251, 251, 251, 1);
		}

		.coverage-detail__column__cell {
			padding: 8px 12px;
			text-align: center;
			width: 50%;
			flex: initial;

			&:nth-child(1) {
				order: 1;
				text-align: left;
				padding-bottom: 0;
				margin-bottom: 5px;

				.item-value {
					font-size: 16px;
					line-height: 1.3;
					font-weight: 600;
					padding: 0;
				}
			}
			&:nth-child(2) {
				order: 3;
				text-align: left;
				padding-top: 0;

				.item-value {
					font-size: 12px;
					line-height: 1.3;
					font-weight: 500;
					color: rgba(109, 121, 134, 1);
					padding: 0;
				}
			}
			&:nth-child(3) {
				order: 2;
				text-align: right;
				padding-bottom: 0;
				margin-bottom: 5px;

				.item-value {
					font-size: 16px;
					line-height: 1.3;
					font-weight: 600;
					padding: 0;
				}
			}
			&:nth-child(4) {
				order: 4;
				text-align: right;
				padding-top: 0;
				.item-value {
					font-size: 12px;
					line-height: 1.3;
					font-weight: 500;
					color: rgba(109, 121, 134, 1);
					padding: 0;
				}
			}

			.target-price {
				margin-left: auto;
				margin-right: auto;
				justify-content: center;

				.target-price__amount {
					padding: 0;
					margin-left: 5px;
				}
			}

			.price-cover-loi {
				width: auto;
				display: inline-block;
				margin-left: auto;
			}
		}
	}

	.table-more-action {
		text-align: center;
		font-size: 16px;
		line-height: 1.3;
		padding: 16px 12px;
		font-weight: 600;
		border: 1px solid rgba(11, 31, 53, 0.4);
		border-radius: 4px;
		color: rgba(11, 31, 53, 0.95);
		margin-top: 40px;

		.link__text {
			border-bottom: none;
		}

		.link__icon {
			display: none;
		}
	}

	.graph-table-wrapper:not(:last-child) {
		margin-bottom: 40px;
	}

	.modal-wrapper {
		.modal {
			width: calc(100% - 24px);
			padding: 12px;
		}

		.research-content__analysts {
			flex-wrap: wrap;
		}
	}

	.custom-filter-section {
		max-width: 0;
	  }

	.custom-column-section {
		min-width: 100%;
		width: 100%;
    	margin-bottom: 30px;
	}

	.table__caption_size_large {
		font-size: 22px;
		line-height:  1.3;
		font-weight: 700;
		padding-bottom: 12px;
	}

	.flex_no-wrap {
		flex-wrap: wrap;
	}

	.custom-page-title {
		margin-left: 0;
		font-weight: 700;
	}

	.custom-margin-bottom {
		margin-bottom: 0;
	}

	.custom-column-section {
		.announcements {
			border-bottom: none;
			padding-left: 12px;
			padding-right: 12px;
		}
	}
	.announcements {
		overflow-x: auto;
		display: flex;
		flex-direction: row;
		margin-bottom: 30px;
		margin-left: -12px;
		margin-right: -12px;
		border-bottom: 1px solid #e8e8e8;
		-ms-overflow-style: none;  /* IE and Edge */
		scrollbar-width: none;  /* Firefox */

		/* Hide scrollbar for Chrome, Safari and Opera */
		&::-webkit-scrollbar {
			display: none;
		}

		& > div:not(:last-child) {
			margin-right: 16px;
		}

		.product {
			background-color: rgba(251, 251, 251, 1);
			border: 1px solid rgba(243, 244, 245, 1);
			min-width: 215px;
			margin-right: 16px;
			border-radius: 8px;
			padding: 12px;
			box-shadow: none;
			margin-bottom: 0;
			height: 100%;

			&:hover {
				.plate__content {
					h5 {
						color: rgba(11, 31, 53, 1);
					}
				}
			}

			.plate__content {
				display: flex;
				flex-direction: column;
				align-items: flex-start;

				.label-tag {
					order: 1;
					display: inline-block;
					margin-bottom: 10px;
				}

				h5 {
					order: 3;
					margin-bottom: 10px;
					margin-top: 0;
					font-size: 15px;
					line-height: 1.3;
					font-weight: 600;

					div {
						margin-top: 5px;
					}

					p {
						font-size: 13px;
						line-height: 1.3;
						color: $red_brand;
						font-weight: 600;
						margin-bottom: 0;
					}
				}

				p {
					order: 2;
					margin-top: 0;
					margin-bottom: 10px;
					font-size: 12px;
					line-height: 1.3;
					color: rgba(109, 121, 134, 1);
				}
			}
		}

		.events {
			  &.events-mobile {
				padding: 0;
				border-radius: 0;
				box-shadow: none;
				margin-bottom: 0;
		  
				.plate__content {
					overflow-x: auto;
					display: flex;
					flex-direction: row;
					padding-bottom: 12px;
					-ms-overflow-style: none;  /* IE and Edge */
					scrollbar-width: none;  /* Firefox */

					/* Hide scrollbar for Chrome, Safari and Opera */
					&::-webkit-scrollbar {
						display: none;
					}

					.events__item {
						position: relative;
						display: flex;
						flex-direction: row;
						align-items: flex-end;
						margin-right: 12px;
						padding-left: 24px;
						margin-bottom: 0;

						&:before {
							content: "";
							display: block;
							position: absolute;
							width: 4px;
							height: 4px;
							background-color: rgba(241, 80, 69, 1);
							top: 50%;
							margin-top: -2px;
							left: 12px;
							border-radius: 50%;
						}

						.label {
							margin-right: 12px;
							color: rgba(241, 80, 69, 1);
						}

						.label,
						.heading {
							white-space: nowrap;
							font-size: 12px;
							line-height: 1.3;
							font-weight: 400;
						}

						.link {
							display: none;
						}
					}
				}
			}
		}
	}

	.user-cart {
		.user-info__photo {
		  margin-right: 12px;
		}

		.user-info {
		  align-items: center;
		  padding: 8px 12px;
		  margin-bottom: 0;
		  padding-left: 0;

		  img {
			width: 24px;
			height: 24px;
			border-radius: 50%;
			border: 1px solid rgba(109, 121, 134, 0.4);
		  }

		  .user-info__detail {
			.fullname {
			  order: 1;
			  font-size: 16px;
			  line-height:1.3;
			  font-weight: 400;
			  color: rgba(11, 31, 53, 1);
			}

			.department {
			  order: 2;
			  font-size: 14px;
			  line-height:1.3;
			  color: rgba(109, 121, 134, 1);
			}
		  }
		}
	  }

	.company-inner {
		margin-left: -12px;
		margin-right: -12px;

		.custom-paddings {
			padding-left: 12px;
			padding-right: 12px;
		}

		& > .announcements {
			display: block;
		}

		.recharts-legend-wrapper {
			display: none;
		}

		.tab-content__detail__company-info {
			width: 100%;
		}

		.tab-content__detail__text {
			width: 100%;

			&.false {
				.paragraph {
					text-overflow: ellipsis;
					overflow: hidden;
					display: -webkit-box;
					-webkit-line-clamp: 5;
					-webkit-box-orient: vertical;
				}
			}

			&.false {
				.more-expand {
					.button {
						&:after {

						}
					}
				}
			}

			&.true {
				.more-expand {
					.button {
						&:after {
							margin-top: 0px;
							transform: rotate(-45deg);
						}
					}
				}
			}

			.more-expand {
				display: flex;
				justify-content: center;

				.button {
					border: none;
					outline: none;
					color: $red_brand;

					&:after {
						content: "";
						display: block;
						position: absolute;
						width: 10px;
						height: 10px;
						border-top: 1px solid $red_brand;
						border-right: 1px solid $red_brand;
						right: 0;
						top: 50%;
						margin-top: -7px;
						transform: rotate(135deg);
					}
				}
			}
		}

		.no-margin-product {
			.heading {
				margin-top: 24px;
			}

			.product {
				padding: 12px;
			}

			.announcements {
				border-bottom: none;
			}
		}

		.report-mar {
			padding-left: 12px;
			padding-right: 12px;

			.modal-wrapper + .button {
				margin-top: 24px;
			}

			form {
				width: 100%;
				max-width: 100%;
			}
		}

		.announcements {
			margin-left: 0;
			margin-right: 0;
		}

		.general-form__tabs {
			max-width: 100%;
		}

		.grid-row {
			margin-left: 0;
			margin-right: 0;
		}

		.grid-col {
			padding: 0;
		}

		.tabs__content {
			min-width: 100%;
		}	

		.company {
			// height: auto !important;
		}

		.button-filter {
			width: 100%;
			margin: 0;

			.tabs__content {
				display: flex;

				.tab-item {
					width: 100%;
					text-align: center;
				}
			}
		}

		.chart-meta {
			padding-left: 12px;
			padding-right: 12px;
			margin-top: 30px;
			margin-bottom: 12px;

			h4 {
				font-size: 20px;
				line-height: 1.3;
				font-weight: 600;
				padding: 0;
				margin-bottom: 8px;
			}

			.stock-title {
				font-size: 15px;
				line-height: 1.3;
				color: rgba(109, 121, 134, 1);
				font-weight: 600;

				.gray-stock {
					font-weight: 600;
				}
			}
		}

		.files-block {
			padding-left: 12px;

			.files-block__item {
				align-items: flex-start;
				border-bottom: 1px solid $borderColor;

				.files-block__item__file-type {
					padding: 0;
					margin-right: 12px;

					svg {
						width: 20px;
						height: 20px;
					}
				}

				.files-block__item__file-name {
					.link {
						font-size: 16px;
						line-height: 1.3;
					}

					.files-block__item__file-size {
						font-size: 13px;
						line-height: 1.3;
					}
				}
			}
		}

		.files-custom-component {
			.tab-content {
				padding-left: 0;
				padding-right: 0;
			}
		}

		.tab-content__detail {
			&.custom-detail {
				padding-left: 12px;
				padding-right: 12px;
			}
		}

		.tab-content {
			padding-left: 12px;
			padding-right: 12px;
			padding-top: 24px;

			.tab-content__detail {
				.tab-content__detail__company-info {
					flex-direction: column;
				}

				.tab-content__detail__text {
					padding-left: 0;
				}
			}
		}

		.ticker {
			padding-top: 0;

			table {
				width: 100%;
				border-collapse: collapse;
			}

			thead {
				display: none;
			}

			.ticker__row {
				padding: 0;
				width: 100%;

				&:nth-child(odd) {
					background-color: rgba(251, 251, 251, 1);
				}

				&.ticker__row_type_body {
					display: flex;
					flex-wrap: wrap;

					.ticker__cell {
						width: 50%;
						font-size: 16px;
						line-height: 1.3;
						font-weight: 600;
						padding: 8px 12px;
						border: none;

						.target-price__amount {
							padding-right: 0;
						}

						&:nth-child(1) {
							text-align: left;
							padding-bottom: 0;
							margin-bottom: 5px;
						}
						&:nth-child(2) {
							text-align: right;
							justify-content: flex-end;
							margin-left: auto;
							padding-bottom: 0;
							margin-bottom: 5px;
							transform: translateY(10px);
						}
						&:nth-child(3) {
							text-align: left;
							padding-top: 0;

							font-size: 12px;
							line-height: 1.3;
							font-weight: 500;
							color: rgba(109, 121, 134, 1);
						}
					}
				}
			}
		}

		.about-company__meta {
			display: flex;
			align-items: flex-start;
			align-items: center;
			padding: 12px;
			margin-bottom: 12px;

			.image-wrapper {
				width: 44px;
				height: auto;
				margin-right: 16px;
			}

			.info {
				h4 {
					font-size: 17px;
					line-height: 1.3;
				}

				span {
					font-size: 15px;
					line-height: 1.3;
					color: rgba(109, 121, 134, 1);
				}
			}
		}

		.about-company__right-block {
			display: none;
		}

		.about-company__wrapper {
			& > .grid-col {
				padding: 0;
				margin-bottom: 24px;
			}
		}

		.about-company__content-block:nth-child(1) { order: 1; }
		.about-company__content-block:nth-child(2) { order: 2; }
		.about-company__content-block:nth-child(3) { order: 3; }

		.about-company__content-block__title {
			font-size: 17px;
			line-height: 1.3;
			font-weight: 600;
			padding: 0;
			display: none;
		}

		.plate {
			padding: 0;
			border-radius: 0;

			.link {
				width: 100%;
			}

			.plate__content {
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
			}

			.user-cart {
				padding-left: 12px;
			}

			.user-info {
				border-bottom: 1px solid $borderColor;
			}
		}
	}
}

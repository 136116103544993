@import '../../styles/variables';

.label-tag {
    display: inline-block;
    font-weight: bold;
    font-size: 14px;
    padding: 7px 25px;
    border-radius: 16px;
    background-color: $dark_indigo_05;
    color: $dark_indigo_60;
    white-space: nowrap;
}
.table-st{
    display: table;
}
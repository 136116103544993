@import '../../styles/variables';

.footer {
    background: #f3f4f5;
    color: #6d7986;
    &__content {
        width: 76%;
        margin: 0 auto;
        padding: 45px 0;
        &-menu {
            display: flex;
            flex-wrap: wrap;
            a {
                font-size: 18px;
                margin-right: 45px;
                &:hover {
                    span {
                        span {
                            border-bottom: none;
                        }
                    }
                }
            }
        }
        &-copyright {
            font-size: 12px;
            margin-top: 49px;
        }
    }

    @media screen and (max-width: $mobile-screen) {
        .footer__content-menu {
            display: none;
        }

        .footer__content {
            margin: 0;
            padding: 0;
            width: 100%;

            .footer__content-copyright {
                margin-top: 0;
                padding: 16px;

                a {
                    color: $red_brand;
                    font-weight: 500;
                }

                .mobile-block {
                    display: block;
                    font-size: 11px;
                    line-height: 1.3;

                    &.alfa-title {
                        font-size: 12px;
                        line-height: 1.3;
                        text-align: center;
                        margin-bottom: 10px;
                        color: rgba(11, 31, 53, 1);
                    }
                }
            }
        }
    }
}
@import '../../styles/variables';

.tree-view {

	&__item {
		padding: 11px 11px 8px 36px;
		color: $dark_indigo;
		
		background-color: #ffffff;
		position: relative;
		cursor: pointer;

		$parentItem: &;

		&--mod {
			&_is-collapsed {
				background-color: $dark_indigo_15;
				&::before {
					content: '';
					display: inline-block;
					position: absolute;
					border-radius: 50%;
					top: 20px;
					width: 6px;
					height: 6px;
					margin-left: -14px;
					background-color: $dark_indigo;
				}
				&:hover {
					.tree-view__parent:hover {
						color: $dark_indigo;
					}
				}
			}
		}
	}

	&__label {
		&--parent {
			font-size: 14px;
			line-height: 22px;
			font-weight: 600;
			&:hover {
				color: $red_brand;
			}
		}
		&--child {
			font-size: 14px;
			line-height: 22px;
			cursor: pointer;
			&:hover {
				color: $red_brand;
			}
		}
	}

	&__container {
		font-size: 14px;
		line-height: 22px;

		&__item {
			padding: 7px 8px 7px 23px;
			position: relative;
			margin-left: 36px;
		}
		.is-check {
			&::before {
				content: url('../../assets/icons/icon_check-default.svg');
				display: inline-block;
				position: absolute;
				top: 10px;
				left: 0;
				margin-left: -4px;
				width: 24px;
				height: 24px;
			}
		}
	}
}
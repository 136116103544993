@import '../../styles/variables';

$speed: 0.6s;
$delay: ($speed * .5);
$easing: cubic-bezier(.55,0,.1,1);

.search-bar {

    &__block {
        position: absolute;
        top: 0;
        transition: 0.4s;
        max-width: 1200px;
        min-width: 910px;
        width: 100%;
        right: 0;
        z-index: -999;
        opacity: 0;
        visibility: hidden;
        // transform: scale(1.2);
        transition: all $speed $easing;
    
        &.is-open {
            opacity: 1;
            visibility: visible;
            z-index: 999;
        }

        &__form {
            background: $dark_indigo_05;
        }

        &__result {
            font-size: 16px;
            font-weight: 600;
            padding-top: 11px;
            background: #fff;
            border-radius: 8px;
            box-shadow: 0px 1px 2px rgba(11, 31, 53, 0.16), 0px 4px 12px rgba(11, 31, 53, 0.08);
            
            visibility: hidden;
            opacity: 0;
            transition: all $speed $easing;
            
            .search-bar-result {
                display: flex;
                flex-direction: row;
                justify-content: left;
                align-items: baseline;
                text-align: left;
                max-height: 258px;
                height: 100%;

                &__detail {
                    &--left-side {
                        width: 50%;
                    }
                    &--right-side {
                        border-left: 1px solid $dark_indigo_05;
                        width: 50%;
                    }
                }
    
            }
    
            &.is-result {
                opacity: 1;
                visibility: visible;
            }
        }
    
        &__bottom {
            padding: 10px 20px 20px 20px;
            text-align: left;
            border-top: 1px solid $dark_indigo_05;
        }
    
        .result-list {
            list-style: none;
            overflow-x: hidden;
            overflow-y: scroll;
            max-height: 258px;
            li {
                display: flex;
                justify-content: space-between;
                padding: 11px 20px 11px 11px;
                cursor: pointer;
                &:hover {
                    background-color: rgba(11, 31, 53, 0.1);
                }
            }
            &.for-right-side {
                li {
                    padding: 11px 40px 11px 20px;
                }
            }
        }
    }

    @media screen and (max-width: $mobile-screen) {
        .search-bar__block {
            min-width: 0;
            max-width: 100%;
            z-index: 1000000;
            padding: 12px 12px;
            border-radius: 8px;

            .search-bar__block__bottom {
                padding-top: 40px;

                .link {
                    width: 100%;
                    display: block;
                    font-size: 12px;
                    line-height: 1.3;
                    color: $red_brand;

                    .link__text {
                        border: none;
                    }

                    .icon {
                        background: none;
                        border-top: 1px solid $red_brand;
                        border-right: 1px solid $red_brand;
                        transform: rotate(45deg);
                        width: 8px;
                        height: 8px;
                    }
                }
            }

            .search-bar__block__form {
                border-radius: 8px;
            }

            .result-list {
                li {
                    flex-direction: column;
                    align-items: flex-start;
                    padding-left: 12px;
                    padding-right: 12px;
                    padding-top: 0;
                    padding-bottom: 0;

                    span {
                        display: block;
                        width: 100%;
        
                        &:first-child {
                            font-size: 13px;
                            line-height: 1.3;
                            font-weight: 400;
                            margin-bottom: 5px;
                            padding-top: 12px;
                        }
        
                        &:last-child {
                            font-size: 12px;
                            line-height: 1.3;
                            font-weight: 400;
                            color: rgba(109, 121, 134, 1);
                            padding-bottom: 12px;
                            border-bottom: 1px solid rgba(11, 31, 53, 0.1);
                        }
                    }
        
                    &:hover {
                        background-color: rgba(11, 31, 53, 0.1);
                    }
                }
            }

            .input__box {
                border: none;
                background: none;
                height: auto;
                box-shadow: none;
            }

            .input_view_filled.input_size_m.input_has-clear .input__clear {
                width: 24px;
                height: 24px;
                margin-top: -12px;
                left: 12px;

                .icon_size_m {
                    width: 18px;
                    height: 12px;
                }
            }

            .input_view_filled.input_size_m .input__control {
                min-height: 0;
                padding: 12px;
                padding-left: 32px;
            }

            .search-bar__block__result {
                margin-top: 68px;
            }

            .search-bar-result {
                max-height: 100%;
            }
        }
    }
}

@import '../../styles/variables';

.ourteam-section {

  &__employee {
    display: grid;
    display: -ms-grid;
    grid-template-columns: repeat(3, 1fr);
    -ms-grid-columns: 1fr 1fr 1fr;
    justify-content: space-between;

    .user-cart:nth-child(1) {
      -ms-grid-column: 1;
      grid-column: 1;
      -ms-grid-row: 1;
      grid-row: 1;
    }
    .user-cart:nth-child(2) {
      -ms-grid-column: 2;
      grid-column: 2;
      -ms-grid-row: 1;
      grid-row: 1;
    }
    .user-cart:nth-child(3) {
      -ms-grid-column: 1;
      grid-column: 1;
      -ms-grid-row: 2;
      grid-row: 2;
    }
    .user-cart:nth-child(4) {
      -ms-grid-column: 3;
      grid-column: 3;
      -ms-grid-row: 1;
      grid-row: 1;
    }
  }

  &__contacts {
    border-bottom: 1px solid $dark_indigo_05;
    &:last-child {
      border-bottom: none;
    }
  }

  &__contacts-header {
    &__mobile {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 10px 5px;
      margin-bottom: 10px;
      border-bottom: 1px solid $borderColor;
      border-top: 1px solid $borderColor;
    }
  }

  h4 {
    font-size: 24px;
    font-weight: 400;
    padding-bottom: 21px;
    line-height: 48px;
    color: #0B1F35;
    margin: 20px 0;
    padding: 0 10px;
  }

  .meta-item {
    display: none !important;
  }

  .Collapsible {
    .Collapsible__trigger {
    }

    .Collapsible__contentOuter {
      .header-trigger {
        display: none;
      }
    }
  }

  .collapse-action {
    display: none;
  }

  @media screen and (max-width: $mobile-screen) {
    h4 {
      font-weight: 700;
      font-size: 22px;
      line-height: 1.3;
      padding-left: 12px;
      padding-right: 12px;
    }

    .collapse-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }

    .collapse-action {
      position: relative;
      display: block;
      border: none;
      background: none;
      outline: none;

      font-weight: 400;
      font-size: 14px;
      line-height: 1.3;
      color: #ef3124;
      padding: 12px;
      padding-right: 36px;

      &.true:after {
        transform: rotate(45deg);
        margin-top: -1px;
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 10px;
        height: 10px;
        border-top: 1px solid #ef3124;
        border-left: 1px solid #ef3124;
        right: 12px;
        top: 50%;
        margin-top: -7px;
        transform: rotate(225deg);
        transition:
          transform .15s ease,
          margin-top .15s ease;
      }

      &__black {
        color:$dark_indigo;

        &:after {
          border-top: 1px solid $dark_indigo;
          border-left: 1px solid $dark_indigo;  
        }
      }
    }

    .ourteam-section__contacts {
      border-bottom: none;

      hr {
        margin: 7px 0;
      }
    }

    .ourteam-section__employee {
      display: flex;
      flex-wrap: wrap;
      .user-cart {
        width: 100%;
      }

      &.employee-managment {
        padding-left: 12px;
        padding-right: 12px;

        .user-cart {
          .user-info,
          .user-info:hover {
            border: 1px solid rgba(243, 244, 245, 1);
            box-shadow:
              0px 1px 2px 0px rgba(11, 31, 53, 0.16),
              0px 4px 12px 0px rgba(11, 31, 53, 0.08);
          }

          .user-info__photo {
            margin-right: 8px;
          }

          .user-info {
            border-radius: 8px;
            margin-bottom: 12px;
            padding: 15px;

            img {
              width: 36px;
              height: 36px;
              border-radius: 50%;
              border: 1px solid rgba(109, 121, 134, 0.4);
            }

            .user-info__detail {
              .fullname {
                font-size: 16px;
                line-height:1.3;
                font-weight: 400;
                color: rgba(11, 31, 53, 1);
              }

              .department {
                font-size: 14px;
                line-height:1.3;
                color: rgba(109, 121, 134, 1);
              }
            }
          }
        }
      }

      &.employee-analysts {
        position: relative;
        border-top: 1px solid $borderColor;
        border-bottom: 1px solid $borderColor;

        & > .user-cart {
          margin-left: 12px;
        }

        .user-cart {
          &:not(:last-child) {
            .user-info {
              border-bottom: 1px solid $borderColor;
            }
          }
        }
      }
    }

    .phone-cart {
      .phone-cart__item {
        padding: 0;
        flex-wrap: wrap;

        .phone-cart__item__phones {
          width: 100%;
        }

        .phone-cart__item__department,
        .phone-cart__item__title {
          width: 100%;
          max-width: 100%;
          padding: 0 12px;
        }

        .meta-item {
          display: block !important;
        }

        .phone-cart__item__department span,
        .phone-cart__item__title span,
        .meta-item {
          font-size: 12px;
          line-height: 1.3;
          color:rgba(109, 121, 134, 1);
        }

        .employee {
          padding-left: 12px;
          display: flex;
          flex-direction: column;

          .employee__fullname,
          .employee__contact {
            color: rgba(11, 31, 53, 1);
            opacity: 1;
          }

          .employee-row {
            position: relative;
            padding: 10px 0;
            border-bottom: 1px solid $borderColor;
            padding-right: 12px;

            &:nth-child(1) { order: 1; }
            &:nth-child(2) { order: 3; border-bottom: none; }
            &:nth-child(3) { order: 2; }
          }
        }
      }
    }

    .Collapsible {
      .Collapsible__trigger {
        &.is-open {
          .phone-cart {
            .phone-cart__item {
              .phone-cart__item__department,
              .phone-cart__item__title {
                &:after {
                  transform: rotate(45deg);
                }
              }
            }
          }
        }

        &.is-closed {
          .phone-cart {
            .phone-cart__item {
              .phone-cart__item__department,
              .phone-cart__item__title {
                &:after {
                  transform: rotate(225deg);
                  margin-top: -4px;
                }

                span {
                  color: rgba(11, 31, 53, 1);
                }
              }
            }
          }
        }

        .phone-cart {
          .phone-cart__item {
            .phone-cart__item__department,
            .phone-cart__item__title {
              padding: 12px;
              border-top: 1px solid $borderColor;
              border-bottom: 1px solid $borderColor;
              position: relative;
              margin-bottom: 10px;

              span {
                font-weight: 400;
                font-size: 16px;
                line-height: 1.3;
                color: #ef3124;
              }

              &:after {
                content: "";
                display: block;
                position: absolute;
                width: 10px;
                height: 10px;
                border-top: 1px solid rgba(11, 31, 53, 1);
                border-left: 1px solid rgba(11, 31, 53, 1);
                right: 12px;
                top: 50%;
                margin-top: 0;
                transform: rotate(45deg);
                transition:
                  transform .15s ease,
                  margin-top .15s ease;
              }
            }

            .phone-cart__item__phones {
              display: none;
            }
          }
        }
      }
  
      .Collapsible__contentOuter {
        .header-trigger {
          display: block;

          .phone-cart__item__department,
          .phone-cart__item__title {
            display: none;
          }
        }
      }
    }
  }
}
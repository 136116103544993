.header {
    top: 0;
    display: flex;
    width: 100%;
    height: 100px;
    box-sizing: border-box;
    padding: 0 0 0 20px;
    margin: 0 auto;
    font-size: 18px;

    &__logo {
        position: relative;
    }

    &__content {
        width: 100%;
        padding-left: 80px;
    }

    &__lang {
        padding: 15px 40px 0;
        flex: 1;
        text-align: right;
    }
}

@import '../../../styles/variables';

.event-info-content {
    font-size: 16px;
    &__top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        &_title {
            padding-bottom: 16px;
            h2 {
                font-weight: bold;
                font-size: 30px;
                line-height: 36px;
            }
        }
        &_date {
            font-size: 18px;
        }
    }

    &__info {
        p {
            padding: 5px 0;
        }
    }

    &__file {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 25px;
        border: 1px solid $dark_indigo_15;
        border-radius: 4px;
        align-items: center;

        &_file-icon {
            padding-right: 35px;
            svg {
                width: 60px;
                height: 60px;
            }
        }

        &_info {
            display: flex;
            flex-direction: column;
            flex: 1;
            &.file-delail {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                flex: 1;
                padding-bottom: 25px;
                .file-date {
                    font-size: 13px;
                }
            }
            &.file-comment {
                p {
                    font-size: 13px;
                }
            }
        }
    }
}
@import '../../../../styles/variables';
.loginForm{
  max-width: 438px;
  width: 100%;
  margin: 70px auto 25px;
  display: block;

  @media screen and (max-width: $mobile-screen) {
    label {
      display: flex;
      justify-content: center;
      font-weight: 600;
      font-size: 15px;
      line-height: 1.3
    }
  }
}


.login-form {

  &__title {
    padding-bottom: 10px;
    font-size: 16px;
    font-weight: 400;
  }

  .field-control {
    padding: 10px 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    label {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: $dark_indigo_60;
    }
    &__section {
      flex: 1;
      padding-right: 20px;
      text-align: center;
    }

    .help-information {
      padding-top: 20px;
      font-size: 13px;
      span {
        color: $red_brand;
        font-size: 13px;
        line-height: 22px;
        letter-spacing: 0.7px;
        font-weight: bold;
      }
    }

  }

  .login-form__input {
    width: 100%;
    border: none;
    padding: 15px 13px;
    background: #F1F3F5;
    border-radius: 5px;
    outline: none;
    font-size: 18px;
    &::placeholder {
      color: rgba(10, 30, 50, 0.3);
    }
  }

  .link-restore {
    text-align: right;
  }


}
@import '../../styles/variables';

.files-block {
    &__item {
        font-size: 18px;
        display: flex;
        padding: 12px 0;
        cursor: default;
        margin-bottom: 5px;
        justify-content: space-between;
        align-items: center;

        &__file-type {
            padding-right: 19px;
        }
        &__file-name {
            flex: 1;
        }
        &__file-size {
            display: block;
            padding: 3px 0;
            color: $dark_indigo_60;
        }
    }
}
@import '../../styles/variables';

.right-sidebar {

  padding-top: 90px;

  &--mod {
    &_p40 {
      padding-top: $padding_40;
    }
    &_p45 {
      padding-top: $padding_45;
    }
    &_p60 {
      padding-top: $padding_60;
    }
    &_p65 {
      padding-top: $padding_65;
    }
    &_p70 {
      padding-top: $padding_70;
    }
    &_p75 {
      padding-top: $padding_75;
    }
    &_p80 {
      padding-top: $padding_80;
    }
    &_p85 {
      padding-top: $padding_85;
    }
  }
}

@import '../../styles/_variables';

.forestcust {
    width: 100%;
    display: block;
    padding: 20px 20px 5px 20px;
    margin-bottom: 0px;
    cursor: pointer;
    background-color: #FAFAFA;
    border-top-color: #EF3124;
    border-top-style: solid;
    &__inner_border {
        margin-left: 52px;
    }
    &__between {
        border-bottom-color: #DBDEE1;
        border-bottom-style: solid;
        border-bottom-width: 0.5px;
    }

}

.forestcust-mobile {
    padding-bottom: -32px;
    margin-bottom: 0px;
    cursor: pointer;

    // * > .files-block2 {
    //     margin-bottom: 5px;
    //     margin-top: 5px;
    //     width: 90vw;
    // }
}
.forestcust-flex-container {
    display: -webkit-flex;
    display: flex;

    // flex-direction: column;
 //чтобы была плавающая ширина блок по содержимому, иначе убрать
    width: 1000px;
    flex-wrap: unset;
    align-content: flex-end;

}
.forestcust-flex-content {
//чтобы была плавающая ширина блок по содержимому, иначе убрать
    max-width: 200px;
// иначе
//  width: 200px;
    margin-right: 5px;
    display: block;
    flex-direction:row;
}

@import '../../styles/_variables';

.product {

    display: block;
    border-radius: 8px;
    padding: 33px 33px 13px 33px;
    margin-bottom: 35px;
    cursor: pointer;
    box-shadow: 0 6px 14px 0 rgba(11,31,53,.15);
    
    &__title {
        overflow: hidden;
        span {
            font-weight: normal;
            opacity: 0.9;
        }
    }

    &__date {
        text-align: right;
        opacity: 0.9;
    }

    &:hover {
        border: 1px $border_plate solid;
        box-shadow: 0 6px 14px 0 rgba(255, 255, 255, 0.15);
        padding: 32px 32px 12px 32px;
        .plate__content {
            h5 {
                color: $red_brand;
            }
        }
    }
    .plate__content {
        overflow: visible;
        word-break: break-word;
    }
}
.lowfile-container {
  margin-top: 10px;
  display: flex
}

.lowfile-item {
  display: inline-block;
  width: 20%;
  padding-left: 0px;
  padding-right: 20px;
}

.lowfile {
    &:hover {
                border-bottom: none;
            }
}
@import '../../../styles/variables';

.user-form {
    font-size: 16px;
    color: $dark_indigo;
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;

    .delivery-data-wrapper {
        display: flex;

        // Hardcode for current approach!
        // Refactoring!
        .Collapsible {
            &:nth-child(n + 2) {
                .input--item--column {
                    &:nth-child(1) {
                        visibility: hidden;
                    }

                    &:nth-child(2) {
                        transform: translateX(-85px);
                    }
                }
            }
        }
    }

    &__setting {
        display: flex;
        flex-direction: row;
        padding: 25px 0;

        &__label {
            flex: 0.8;
            padding-right: 25px;
        }
        &__input {
            flex: 3;
            flex: 3 1;
            flex-direction: row;
            display: flex;
            
            .save-button {
                height: 30px;
                font-size: 13px;
                padding: 0 44px;
                font-weight: 500;
            }

            .input-group {
                width: 35%;

                button {
                    margin: 0 12px;
                }
            }
        }
    }

    .label-chek-group {
        flex: 1;
        display: flex;
        flex-direction: row;

        &__content {
            flex: 1;
            display: flex;
            flex-direction: column;
            padding-left: 16px;

            span {
                font-size: 16px;
                line-height: 22px;
                display: block;
                color: #0B1F35;
                padding-bottom: 5px;
                &:first-child {
                    font-weight: bold;
                    font-size: 18px;
                    color: #0B1F35;
                }
            }
        }
    }

    .input-list {
        list-style-type: none;
        &__lvl {
            list-style-type: none;
            padding-left: 30px;
        }
    }

    .border {
        border-bottom: 1px solid $dark_indigo_15;
    }

    @media screen and (max-width: $mobile-screen) {
        padding-top: 32px;

        .delivery-data-wrapper {
            display: block;
    
            // Hardcode for current approach!
            // Refactoring!
            .Collapsible {
                .input--item {
                    display: flex;
                    flex-direction: column;
                }

                .input--item--column,
                .input--item__col {
                    width: 100%;
                }

                &:nth-child(n + 2) {
                    .input--item--column {
                        &:nth-child(1) {
                            visibility: visible;
                        }
    
                        &:nth-child(2) {
                            transform: translateX(0);
                        }
                    }
                }
            }
        }

        .button-float {
            width: 100%;
            padding: 12px;
            margin-bottom: 24px;
            font-size: 16px;
            line-height: 1.3;
            height: 44px;
        }

        .form-trigger-info {
            padding-bottom: 0;

            .form-row__cell {
                display: none;
            }

            .user-form__setting__label {
                font-size: 20px !important;
                line-height: 1.3;
                font-weight: 600;
            }
        }

        .Collapsible__contentInner {
            .user-form__setting {
                padding-bottom: 0;
            }
        }

        .Collapsible__trigger {
            &.is-closed {
                .form-trigger-info-mobile {
                    .input--item {
                        .input--item__col {
                            &:after {
                                margin-top: -7px;
                                transform: rotate(135deg);
                            }
                        }
                    }
                }
            }
        }

        .form-trigger-info-mobile {
            padding-bottom: 0;

            .user-form__setting__label {
                display: none;
            }

            .form-row__cell {
                margin-left: -12px;
                width: calc(100% + 12px) !important;
            }

            .input--item {
                border-top: 1px solid rgba(11, 31, 53, 0.1);
                border-bottom: 1px solid rgba(11, 31, 53, 0.1);

                .input--item__col {
                    padding: 12px;

                    &:after {
						content: "";
						display: block;
						position: absolute;
						width: 10px;
						height: 10px;
						border-top: 1px solid;
						border-right: 1px solid;
						right: 16px;
						top: 50%;
						margin-top: -3px;
						transform: rotate(-45deg);
					}
                }
            }
        }

        .user-form__setting {
            flex-direction: column;
            align-items: flex-start;
            margin-right: -12px;

            .input--item {
                padding: 0;
            }

            .user-form__setting__label {
                font-size: 12px;
                line-height: 1.3;
            }

            .input__control {
                font-size: 16px;
                line-height: 1.3;
            }

            .input {
                width: 100%;
            }

            .label-chek-group {
                .label-chek-group__content {
                    span:nth-child(1) {
                        font-size: 20px;
                        line-height: 1.3;
                    }

                    span:nth-child(2) {
                        margin-left: -38px;
                        margin-top: 5px;
                    }
                }
            }

            .group-checkbox-title {
                margin-top: 32px;
                margin-bottom: 16px;
            }

            .checkbox-group {
                width: 100%;

                display: flex;
                flex-direction: column;

                .checkbox-group__label {
                    order: 2;
                    margin-right: 12px;
                    margin-top: 12px;

                    .checkbox__box {
                        display: none;
                    }

                    .checkbox {
                        background-color: rgba(240, 50, 38, 0.85);
                        border: 1px solid rgba(240, 50, 38, 0.85);
                        color: #fff;
                        border-radius: 4px;
                        text-align: center;
                        display: flex;
                        justify-content: center;
                    }

                    .checkbox__text {
                        display: block;
                        color: #fff;
                        font-weight: 600;
                        font-size: 16px;
                        line-height: 1.3;
                    }
                }

                .checkbox-group__box {
                    order: 1;
                }
            }

            .input__group-checkbox-items {
                padding: 0;
            }

            .input-group {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .input-group__input-case {
                    padding-left: 0;
                    width: 100%;
                }
            }

            .checkbox-group__label {
                margin-bottom: 0;
            }

            .checkbox {
                width: 100%;
                padding-right: 12px;

                display: flex;
                justify-content: space-between;
                margin-right: 0;
                margin-bottom: 0 !important;
                padding-top: 12px;
                padding-bottom: 12px;
                border-bottom: 1px solid rgba(11, 31, 53, 0.1);
                font-size: 16px;
                line-height: 1.3;

                .checkbox__box { order: 2; }
                .checkbox__text {
                    order: 1;
                    font-size: 16px;
                    line-height: 1.3;
                    color: $dark_indigo;
                    padding-left: 0;
                }
            }

            &.prof-border {
                border: none;
            }

            .prof-radio {
                .input--item {
                    .input--item__col {
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-start;
                    }

                    .radio {
                        display: flex;
                        justify-content: space-between;
                        margin-right: 0;
                        padding-top: 12px;
                        padding-bottom: 12px;
                        padding-right: 12px;
                        border-bottom: 1px solid rgba(11, 31, 53, 0.1);
                        font-size: 16px;
                        line-height: 1.3;

                        .radio__box { order: 2; margin-right: 0; }
                        .radio__text { order: 1; }
                    }
                }
            }
        }
    }

    @media screen and (min-width: $mobile-screen + 1px) {
        .form-trigger-info-mobile {
            display: none;
        }

        .group-checkbox-title {
            display: none;
        }
    }
}

.checkbox_theme_alfa-on-white {
    width: 100%;
}
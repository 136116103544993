@import '../../styles/variables';

.coverage-list {
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  padding-left: 26%;

  &__caption {
    line-height: 48px;
    font-size: 24px;
    padding-bottom: 21px;
  }

  &__row-head {
    font-size: 18px;
    display: flex;
    padding: 12px 0;
    cursor: default;
    opacity: 0.7;

    div {
      &:first-child {
        flex: 2;
      }

      flex: 1;
    }
  }

  .coverage-detail {
    display: flex;
    flex-direction: row;
    padding-bottom: 30px;

    &__column {
      flex: 4;

      &--current {
        flex: 2;
      }

      &__cell {
        flex: 1;
      }

      &__row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: baseline;
      }
    }
  }

  .item-value {
    display: block;
    font-size: 18px;
    padding: 5px 0;

    &_company_name {
      color: $red_brand;
      font-weight: 600;
    }

    &_company_ticker {
      font-weight: 600;
    }

    &_company_price {
      font-weight: 700;
      color: $dark_indigo_60;
    }

    &_type_up {
      color: $green_done;
    }

    &_type_down {
      color: $red_brand;
    }
  }

}

.empty-value-block {
  display: block;
  text-align: center;
  font-size: 24px;
}

.price-cover-loi{
  width: 82px;
}
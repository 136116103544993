@import '../../styles/variables';

.events {
    display: block;
    padding: 33px;
    margin-bottom: 35px;

    &__item {
        display: block;
        margin-bottom: 40px;

        &_title {
            margin: 0;
        }
    }

    &__bottom-link {
        display: block;
        text-align: right;
        font-size: 14px;
        .link__icon {
            margin-left: 0;
        }
    }

}
@import '../../styles/variables';

.menu-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $border_plate;
    padding-right: 20px;
    &__menu {
        padding: 15px 0 15px 0;
        font-size: 16px;
        flex: 2;
        li {
            position: relative;
            display: inline-block;
            margin-right: 60px;
            .menu__link {
                a {
                    border: 0;
                    color: rgba(10, 30, 50, 0.9);
                }
                a:hover {
                    color: $red_brand;
                }
                &--active {
                    font-weight: 500;
                    color: $dark_indigo;
                    &::before {
                        position: absolute;
                        bottom: -17px;
                        width: 100%;
                        height: 3px;
                        background-color: $red_brand;
                        content: '';
                    }
                }
            }
        }
    }
    &__search-bar {
        text-align: end;
    }

    @media screen and (max-width: $mobile-screen) {
        .header__user {
            padding-top: 20px;

            h4 {
                font-size: 15px;
                line-height: 1.3;
                font-weight: 600;
                color: $dark_indigo;
            }
        }

        .mobile-menu-header {
            display: flex !important;
            justify-content: space-between;
            align-items: center;
            padding-left: 16px;
            padding-right: 16px;
            margin-bottom: 30px;
        }

        .menu-wrapper__menu {
            display: none;
        }

        .bm-menu-wrap {
            .menu-wrapper__menu {
                padding: 0;
                flex-direction: column;

                li {
                    border-bottom: 1px solid rgba(109, 121, 134, 0.4);
                    margin-right: 0;
                    .menu__link {
                        a {
                            position: relative;
                            display: block;
                            padding: 18px 16px;
                            font-size: 16px;
                            line-height:1.3;
                            font-weight: 600;

                            &:before {
                                content: none;
                            }

                            &:after {
                                content: "";
                                display: block;
                                position: absolute;
                                width: 12px;
                                height: 12px;
                                top: 50%;
                                margin-top: -6px;
                                right: 16px;
                                border-top: 1px solid rgba(109, 121, 134, 0.4);
                                border-right: 1px solid rgba(109, 121, 134, 0.4);
                                transform: rotate(45deg);
                            }

                            &.menu__link--active {
                                background-color: rgba(11, 31, 53, 0.1);
                            }
                        }
                    }
                }
            }
        }
    }
}

// Slide menu
.bm-burger-button {
    position: absolute;
    width: 24px;
    height: 24px;
    left: 12px;
    top: 50%;
    margin-top: -12px;
}

.bm-burger-bars {
    background: #373a47;
}

.bm-burger-bars-hover {
    background: #a90000;
}

.bm-cross-button {
    display: none;
}

.bm-cross {
    background: #bdc3c7;
}

.bm-menu-wrap {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
}

.bm-menu {
    background: #fff;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;

    .menu-wrapper__menu {
        display: flex;
    }
}

.bm-morph-shape {
    fill: #373a47;
}

.bm-item-list {
    color: #b8b7ad;
}

.bm-item {
    display: inline-block;
}

.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
    top: 0;
    left: 0;
}

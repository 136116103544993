@import '../../styles/variables';

.target-price {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    color: $dark_indigo;

    &__amount {
        padding: 0 10px;

        .price-status {

            &--down {
                .label {
                    color: $red_brand;
                    font-weight: bold;
                }
            }
            &--up {
                .label {
                    color: $green_done;
                    font-weight: bold;
                }
            }
            &--default {
                .label {
                    color: $dark_indigo_60;
                    font-weight: bold;
                }
            }
        }

    }

}